import React from 'react'
import './about.css'
import AboutImg from '../../assets/about.png'
import CV from '../../assets/Arsalan CV.pdf'
import Info from './Info'

const About = () => {
  return (
    <section className="about section" id="about">
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">My Introduction</span>
      <div className="about__container container grid">
        <img src={AboutImg} alt="AboutImg" className="about__img" />
        <div className="about__data">
          <Info />
          <p className="about__description">I have done Geo-Informatics Engineering from National University of Sciences and Technology, Islamabad Pakistan. My core domain of work is WebGIS mapping, GIS Analysis, GIS programming (Raster and Vector Geoprocessing), Data Analytics using ElasticSearch Logstash and Kibana (ELK Stack), Data Scraping & Automation, Data Preparation and Data Visualization.
          </p>
          <a download="" href={CV} className="button button--flex">Download my CV<i class="uil uil-file-download-alt about__cv-icon"></i></a>
        </div>
      </div>
    </section>
  )
}

export default About