import Image1 from '../../assets/testimonials/test1.png'
import Image2 from '../../assets/testimonials/test2.png'
import Image3 from '../../assets/testimonials/test3.png'
import Image4 from '../../assets/testimonials/test4.png'
import Image5 from '../../assets/testimonials/test5.png'
import Image6 from '../../assets/testimonials/test6.png'
import Image7 from '../../assets/testimonials/test7.png'
import Image8 from '../../assets/testimonials/test8.png'
import Image9 from '../../assets/testimonials/test9.png'
import Image10 from '../../assets/testimonials/test10.png'
import Image11 from '../../assets/testimonials/test11.png'
import Image12 from '../../assets/testimonials/test12.png'
import Image13 from '../../assets/testimonials/test13.png'
import Image14 from '../../assets/testimonials/test14.png'
import Image15 from '../../assets/testimonials/test15.png'
import Image16 from '../../assets/testimonials/test16.png'
import Image17 from '../../assets/testimonials/test17.png'
import Image18 from '../../assets/testimonials/test18.png'
import Image19 from '../../assets/testimonials/test19.png'
import Image20 from '../../assets/testimonials/test20.png'
import Image21 from '../../assets/testimonials/test21.png'
import Image22 from '../../assets/testimonials/test22.png'
export const Data = [
    {
        id: 1,
        image: Image1,
        title: "Sebastian Loww",
        description: "Very reliable and professional work. Quick replies and thoroughly worked through work delivered in time. Huge recommendation!"
    },
    {
        id: 2,
        image: Image2,
        title: "Julian Hope",
        description: "I needed his help with creating a variety of different charts for a project. Arsalan did an amazing job with building those out and making them visually appealing."
    },
    {
        id: 3,
        image: Image3,
        title: "Sara Nasri",
        description: "Very prompt turnaround for our project, competent and high quality work. This was not a fun, sexy, creative project - it was very straightforward and he did it perfectly."
    },
    {
        id: 4,
        image: Image4,
        title: "Sup Yoga",
        description: "He always replies very fast. Always willing to do the job right! Very professional and extremely patience. I’m very satisfied with the job he did for me."
    },
    {
        id: 5,
        image: Image5,
        title: "Zebian Neroth",
        description: "I would say, Arsalan is very professional in his job, extremely helpful in every aspect of the job required by me, and very fast delivery. Thanks Arsal"
    },
    {
        id: 6,
        image: Image6,
        title: "Zdenko Kurtovic",
        description: "Very communicative and dedicated person, my compliments."
    },
    {
        id: 7,
        image: Image7,
        title: "Tanner Markley",
        description: "Good communication, technically proficient and excellent documentation."
    },
    {
        id: 8,
        image: Image8,
        title: "Richie Rich",
        description: "Very professional service. Good communication and delivery as promised."
    },
    {
        id: 9,
        image: Image9,
        title: "Tarek",
        description: "Arsal is an amazing person. He helped me with my postgresql project. Always available for professional support. I'd highly recommend working with him."
    },
    {
        id: 10,
        image: Image10,
        title: "Andrew Lowden",
        description: "Out of all the people I have worked with over the years arsalan has been the best. Super thorough in his work and great communicating. All work comes with a detailed how to guide."
    },
    {
        id: 11,
        image: Image11,
        title: "George Elliot",
        description: "I was super satisfied. He was patient, very responsive and helpful. I am definitely do any coding related project again with him! I am so happy!"
    },
    {
        id: 12,
        image: Image12,
        title: "Amel Sebisa",
        description: "Serious and motivated salesman who does not hesitate to spend time to satisfy the customer. I highly recommend him!"
    },
    {
        id: 13,
        image: Image13,
        title: "Alexandre",
        description: "Thank you arsal for this very pleasant collaboration, your availability and your professionalism. I will come back to you soon for a new project."
    },
    {
        id: 14,
        image: Image14,
        title: "Sandro Alvarez",
        description: "A highly admirable person who provided instant feedback, knowledgeable responses, flexible revisions, and provided updates through each step of his working progress."
    },
    {
        id: 15,
        image: Image15,
        title: "Jacob Velazquez",
        description: "We got our goal so fast, that i am reallly happy to work with him. He solved anything and came up with great ideas for design. Really appreciate his work"
    },
    {
        id: 16,
        image: Image16,
        title: "Dougtera",
        description: "Great experience, really appreciated the response time and dedication to completing the project quickly and accurately. Highly Recommended!"
    },
    {
        id: 17,
        image: Image17,
        title: "William Bush",
        description: "This person did an excellent job with the required project. They helped save a large amount of time with my project! Would highly recommend!"
    },
    {
        id: 18,
        image: Image18,
        title: "Met Ponta",
        description: "Super professional! The comunication was excellent and the job was very quickly. Very recommended"
    },
    {
        id: 19,
        image: Image19,
        title: "Ratim Chakrabor",
        description: "Two words - perfect guy"
    },
    {
        id: 20,
        image: Image20,
        title: "Franjhoo",
        description: "If you need any help with PostgreSQL, this is the man for the job! Everything went smooth, great communication and fixed the small errors in no time."
    },
    {
        id: 21,
        image: Image21,
        title: "Maha Ramira",
        description: "Great service! The seller would be willing to go through things comprehensively. Very fast response and well communicated towards meeting my own needs. Highly recommended to use this service."
    },
    {
        id: 22,
        image: Image22,
        title: "Vishal Singh",
        description: "Very skilled, good communication and highly recommendeded."
    }
]