import React from 'react'

const WebGIS = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">WEB GIS</h3>
        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">Web mapping using Mapbox studio</p>
                        <span className="skills__level">8/10</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">Interactive web mapping using Python + JS + Postgres + Mapbox GL JS</p>
                        <span className="skills__level">7/10</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">GeoServer / Leaflet / Maputnik / OpenLayers / Tegola</p>
                        <span className="skills__level">7/10</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WebGIS