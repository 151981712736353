import React from 'react'

const Python = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">PYTHON</h3>
        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">Automation and scraping</p>
                        <span className="skills__level">8/10</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">Raster and vector geoprocessing</p>
                        <span className="skills__level">9/10</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">Data preparation and visualization</p>
                        <span className="skills__level">7/10</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Python