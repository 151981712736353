import React from 'react'
import './skills.css'
import Python from './Python'
import GIS from './GIS'
import WebGIS from './WebGIS'
import DataAnalytics from './DataAnalytics'
import Frontend from './Frontend'

const Skills = () => {
  return (
    <section className="skills section" id="skills">
        <h2 className="section__title">Skills</h2>
        <span className="section__subtitle">My Technical Level</span>

        <div className="skills__container container grid">
            <GIS />
            <WebGIS />
            <DataAnalytics />
            <Python />
            <Frontend />
        </div>
    </section>
  )
}

export default Skills