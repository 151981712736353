import React from 'react'

const GIS = () => {
  return (
    <div className="skills__content">
        <h3 className="skills__title">GIS</h3>
        <div className="skills__box">
            <div className="skills__group">
                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">ArcGIS / ArcGIS Pro / QGIS / ERDAS Imagine / SNAP / ENVI / Google Earth Engine / Google Earth Pro</p>
                        <span className="skills__level">9/10</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">GIS Modeling (Site Suitability, Species Habitat, Landslide Prediction, Streamflow Prediction)</p>
                        <span className="skills__level">8/10</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">GIS Analysis (Watershed Analysis, Viewshed Analysis)</p>
                        <span className="skills__level">9/10</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">Map Making (Cartographic maps, Topographic maps)</p>
                        <span className="skills__level">10/10</span>
                    </div>
                </div>

                <div className="skills__data">
                    <i class='bx bx-badge-check' ></i>
                    <div>
                        <p className="skills_name">Land Use Land Cover Classification</p>
                        <span className="skills__level">10/10</span>
                    </div>
                </div>
            </div>


        </div>
    </div>
  )
}

export default GIS